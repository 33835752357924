'use client';

import SvgFileQuestion03 from '@unifyapps/icons/outline/FileQuestion03';
import Stack from '@unifyapps/ui/_components/Stack';
import type { EmptyStateProps } from '@unifyapps/ui/components/EmptyState';
import { EmptyState } from '@unifyapps/ui/components/EmptyState';
import { Link } from '@unifyapps/carbon/navigation';
import { useTranslation } from '@unifyapps/i18n/client';

function NotFound({
  description,
  title,
  buttonProps,
}: {
  title?: string;
  description?: string;
  buttonProps?: EmptyStateProps['secondaryButton'];
}) {
  const { t } = useTranslation();
  return (
    <Stack className="h-screen w-full">
      <EmptyState
        Icon={SvgFileQuestion03}
        description={description || t('common:NotFound.Description')}
        secondaryButton={{
          href: '/',
          component: Link,
          label: t('common:BackToHome'),
          color: 'brand',
          variant: 'solid',
          ...buttonProps,
        }}
        slotProps={{
          featuredIcon: {
            color: 'gray',
          },
        }}
        title={title || t('common:NotFound.Title')}
      />
    </Stack>
  );
}

export default NotFound;
