'use client';

/* eslint-disable @typescript-eslint/no-restricted-imports -- needed here */
import { forwardRef } from 'react';
import type { LinkProps } from 'next/link';
import NextLink from 'next/link';
import { useParams } from 'next/navigation';
import { getAdaptedProjectsRoute } from './project/utils';

// PREFETCH to false when running tests with webdriver,
// else use default prefetch
const PREFETCH = typeof navigator !== 'undefined' && navigator.webdriver ? false : undefined;

const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const { projectSlug } = useParams<{ projectSlug?: string }>();

  return (
    <NextLink
      prefetch={PREFETCH}
      {...props}
      href={getAdaptedProjectsRoute(props.href as string, projectSlug)}
      ref={ref}
    />
  );
});

export default Link as typeof NextLink;
